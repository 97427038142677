import React, { useEffect, useRef } from "react";

const PostContent = ({ content }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const links = contentRef.current.querySelectorAll("a");
      
      links.forEach((link) => {
        if (link.getAttribute("href")?.startsWith("?")) {
          link.href = "https://www.facebook.com/" + link.getAttribute("href");
        }
      });
    }
    document.querySelectorAll('.ad-lib-second-html .x6ikm8r.x10wlt62.xlyipyv.x5e6ka.xb2kyzz ._4ik4._4ik5').forEach(element => {
        if (!element.textContent.trim()) {
          element.style.display = 'none';
        }
      });
  }, [content]);

  return <div className="second-html" ref={contentRef} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default PostContent;
