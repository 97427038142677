import { React, useEffect, useState } from "react";
import axios from "axios";
import './SuccessScreen.scss'
import SuccessChrome from "../../../assets/images/successChrome.svg";
import GreenChrome from "../../../assets/images/greenChrome.svg";

function SuccessScreen() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paymentDefault = urlSearchParams.get('paymentDefault');
    const token = localStorage.getItem("token");
    const fetchActiveSubscription = async () => { 
      try{
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.paymentMethods && paymentDefault){
          if(response.data.data.paymentMethods[0].id){
              const responses = await axios.post(`${process.env.REACT_APP_API}subscriptions/update-default-paymentMethod`, {"paymentMethod": response.data.data.paymentMethods[0].id},
                {
                  headers: {
                      Authorization: `Bearer ${token}`,
                    }
                }
              );
              console.log(responses);
          }
        }
        const responses = await axios.get(
          `${process.env.REACT_APP_API}users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (responses?.data?.data?.isActiveSubscription){
            pushCustomerToGTM(responses?.data?.data?.email, responses?.data?.data?.firstName, responses?.data?.data?.lastName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    function pushCustomerToGTM(email, firstName, lastName) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                customer: {
                    billing: {
                        email: email,
                        first_name: firstName,
                        last_name: lastName
                    }
                }
            }
        });
    }
    fetchActiveSubscription();

  }, []);
  return (
    <div className="sucess-section"> 
      <div className="success-content">Success! You're now subscribed.</div>
      <div>
        <a href="https://chromewebstore.google.com/detail/zimzee/mnocfefponilgkmehcdbmdojkeodoffd" target="_blank"><img style={{width: "184px",paddingBottom: "30px",margin: "0 auto"}} src={SuccessChrome} alt="chromeExtension" /></a>
        <div className="successbutton-content"><button className="flex" style={{margin: "0 auto", alignItems: "center"}}><img style={{marginRight: "7px"}} src={GreenChrome} alt="greenChrome" />Chrome Extension</button></div>
        <div className="extension-content">DO THIS FIRST!</div>
        <div className="extensiondetail-content">Please install the Chrome extension by clicking here: <span className="download-content"><a href="https://chromewebstore.google.com/detail/zimzee/mnocfefponilgkmehcdbmdojkeodoffd" target="_blank">Download Chrome Extension</a></span></div>
        <div className="downloadext-content"><a href="https://chromewebstore.google.com/detail/zimzee/mnocfefponilgkmehcdbmdojkeodoffd" target="_blank">Welcome to Zimzee!</a></div>
      </div>
    </div>
  );
}
export default SuccessScreen;
